import React, { useState, useRef, useEffect } from 'react';
import { Github, Linkedin, Mail, Check, Cloud, Database, Server, Code, Languages, Activity, FileText, ArrowLeft, ArrowRight } from 'lucide-react';

const Portfolio = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [showScrollButtons, setShowScrollButtons] = useState({ left: false, right: false });
  const [expandedItems, setExpandedItems] = useState({});
  const blogsContainerRef = useRef(null);
  const toggleExpand = (type, id) => {
    setExpandedItems(prev => ({
      ...prev,
      [`${type}-${id}`]: true  // Only allows expanding, no collapse
    }));
  };

  const updateScrollButtons = () => {
    if (blogsContainerRef.current) {
      const container = blogsContainerRef.current;
      setShowScrollButtons({
        left: container.scrollLeft > 0,
        right: container.scrollLeft < (container.scrollWidth - container.clientWidth)
      });
    }
  };

  useEffect(() => {
    updateScrollButtons();
    window.addEventListener('resize', updateScrollButtons);
    return () => window.removeEventListener('resize', updateScrollButtons);
  }, []);

  const blogs = [
    {
      title: "Building a Cost-Efficient Video Processing Pipeline on AWS",
      description: "Learn how to build a scalable and cost-efficient video processing pipeline using AWS services like Lambda, S3, and MediaConvert. This article covers architectural decisions, implementation details, and cost optimization strategies.",
      link: "https://www.linkedin.com/pulse/building-cost-efficient-video-processing-pipeline-aws-pratap-singh-xx9bc/",
      image: "article1.jpg",
      date: "Oct 27, 2024",
      readTime: "5 min read",
      type: "linkedin"
    },
    {
      title: "Making a voice-based browser friendly game",
      description: "A deep dive into creating an accessible browser game using voice commands. This article explores the implementation of voice recognition APIs, real-time audio processing, and creating an engaging user experience through voice interactions.",
      link: "https://www.linkedin.com/pulse/making-voice-based-browser-friendly-game-dushyant-pratap-singh-5xagc/?trackingId=4shdNIkbR9SNya6FU5dhHQ%3D%3D",
      image: "flappy.jpeg",
      date: "Oct 20, 2024",
      readTime: "5 min read",
      type: "linkedin"
    },
    {
      title: "Computer Networks: Fundamentals",
      description: "An comprehensive guide to computer networking fundamentals. This article covers essential concepts including TCP/IP protocols, network architecture, routing algorithms, and best practices for network security and performance optimization.",
      link: "https://www.linkedin.com/pulse/computer-networks-fundamentals-dushyant-pratap-singh/?trackingId=7rh8pn9HQIyCspi4KhYsyw%3D%3D",
      image: "image.gif",
      date: "Mar 19, 2023",
      readTime: "10 min read",
      type: "linkedin"
    }
  ];

  const skillCategories = [
    {
      name: "Cloud",
      icon: <Cloud className="w-8 h-8 text-blue-400" />,
      skills: ["AWS", "CI-CD", "ECS", "Codebuild", "Lambda", "OpenAI"]
    },
    {
      name: "Database",
      icon: <Database className="w-8 h-8 text-green-400" />,
      skills: ["Elasticsearch", "MongoDB", "PostgresSQL", "Redis"]
    },
    {
      name: "Event Store",
      icon: <Server className="w-8 h-8 text-yellow-400" />,
      skills: ["Apache Kafka"]
    },
    {
      name: "Framework",
      icon: <Code className="w-8 h-8 text-purple-400" />,
      skills: ["NodeJS", "Express", "Jest", "Jmeter", "FastAPI"]
    },
    {
      name: "Language",
      icon: <Languages className="w-8 h-8 text-red-400" />,
      skills: ["Javascript", "Typescript", "Python3"]
    },
    {
      name: "Monitoring Tools",
      icon: <Activity className="w-8 h-8 text-indigo-400" />,
      skills: ["Prometheus", "Grafana", "Loki", "BetterStack"]
    }
  ];

  const projects = [
    {
      title: "Chidia Udd",
      description: "A voice-controlled Indian version of Flappy Bird where voice commands let the bird fly and switch avatars in real-time.",
      link: "https://projects.iamdushyant.in/chidia_udd",
      image: "flappy_bird.jpg"
    },
    {
      title: "Fiverr Backend",
      description: "Backend API for a job platform where contractors can be paid.",
      link: "https://github.com/dushyant0814/fiverr-backend",
      image: "fiverr.jpg"
    },
    {
      title: "Portfolio Manager",
      description: "Backend service for managing portfolios and trading securities.",
      link: "https://github.com/dushyant0814/portfolio-manager-backend",
      image: "zerodha.png"
    }
  ];

  const achievements = [
    {
      title: '"Take Ownership" Award at Grappus',
      description: "Recognized for sharing credit, accepting responsibility, and fostering a dedicated, all-in culture.",
      image: "take.png"
    },
    {
      title: "ACM ICPC 2017",
      description: "Achieved team rank 81 at the Asia Kolkata-Kanpur Regional Contest.",
      link: "https://icpc.global/ICPCID/84BAPWTTOH50",
      image: "acm1.png"
    },
    {
      title: "CodeChef Certified",
      description: "Advanced level certification in Data Structures & Algorithms.",
      link: "https://www.codechef.com/certificates/public/87816a4",
      image: "algorithm.avif"
    }
  ];

  const copyEmail = () => {
    navigator.clipboard.writeText('iamdushyant865@gmail.com').then(() => {
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
    });
  };

  const scrollLeft = () => {
    const container = blogsContainerRef.current;
    if (container) {
      container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
      setTimeout(updateScrollButtons, 500);
    }
  };

  const scrollRight = () => {
    const container = blogsContainerRef.current;
    if (container) {
      container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
      setTimeout(updateScrollButtons, 500);
    }
  };

  const resumeUrl = "https://drive.google.com/file/d/1dyYGpXOIStSu4KORp49ftSWD87_98Pgo/view?usp=sharing";
  const profilePicture = "dushyant.jpeg";

  return (
    <div className="bg-black text-white font-sans">
      <div className="min-h-screen flex flex-col">
        <header className="py-8 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row items-center sm:items-start justify-center sm:justify-start space-y-4 sm:space-y-0 sm:space-x-6">
            <img
              src={profilePicture}
              alt="Dushyant Pratap Singh"
              className="w-32 h-32 rounded-full object-cover border-2 border-white-500"
            />
            <div className="text-center sm:text-left">
              <h1 className="text-4xl font-bold tracking-tight">Dushyant Pratap Singh</h1>
              <p className="text-xl mt-2 mb-3 text-gray-400">Senior Software Engineer | Backend Developer</p>
              <div className="flex justify-center sm:justify-start items-center space-x-6">
                <a href="https://github.com/dushyant0814" className="text-gray-400 hover:text-white transition-colors" target="_blank" rel="noopener noreferrer">
                  <Github size={24} />
                </a>
                <a href="http://www.linkedin.com/in/dushyant865" className="text-gray-400 hover:text-white transition-colors" target="_blank" rel="noopener noreferrer">
                  <Linkedin size={24} />
                </a>
                <a href={resumeUrl} className="text-gray-400 hover:text-white transition-colors" target="_blank" rel="noopener noreferrer">
                  <FileText size={24} />
                </a>
                <button onClick={copyEmail} className="text-gray-400 hover:text-white transition-colors focus:outline-none">
                  <Mail size={24} />
                </button>
              </div>
            </div>
          </div>
        </header>

        <main className="flex-grow px-4 sm:px-6 lg:px-8">
          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-4">About Me</h2>
            <p className="text-xl text-gray-300 leading-relaxed">
              Senior software engineer with 5 years of experience in scalable microservices, cloud infrastructure, and team leadership. Proven in enhancing system reliability, optimizing resources, and mentoring developers to drive operational efficiency and performance improvement.
            </p>
          </section>

          <section className="mb-16">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-3xl font-bold">Featured Articles</h2>
              <div className="flex space-x-4">
                <button
                  onClick={scrollLeft}
                  className={`p-2 rounded-full transition-colors ${showScrollButtons.left
                      ? 'bg-gray-800 hover:bg-gray-700 text-white'
                      : 'bg-gray-900 text-gray-600 cursor-not-allowed'
                    }`}
                  disabled={!showScrollButtons.left}
                  aria-label="Scroll left"
                >
                  <ArrowLeft size={24} />
                </button>
                <button
                  onClick={scrollRight}
                  className={`p-2 rounded-full transition-colors ${showScrollButtons.right
                      ? 'bg-gray-800 hover:bg-gray-700 text-white'
                      : 'bg-gray-900 text-gray-600 cursor-not-allowed'
                    }`}
                  disabled={!showScrollButtons.right}
                  aria-label="Scroll right"
                >
                  <ArrowRight size={24} />
                </button>
              </div>
            </div>
            <div
              ref={blogsContainerRef}
              className="flex overflow-x-auto scroll-smooth hide-scrollbar gap-8"
              onScroll={updateScrollButtons}
            >
              {blogs.map((blog, index) => (
                <div
                  key={index}
                  className="flex-none w-full md:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)] bg-gray-900 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300"
                >
                  <div className="relative">
                    <img
                      src={blog.image}
                      alt={blog.title}
                      className="w-full h-48 object-cover"
                    />
                    {/* {blog.type === 'linkedin' && (
                      <div className="absolute top-4 left-4">
                        <Linkedin className="w-6 h-6 text-blue-500 bg-white rounded-full p-1" />
                      </div>
                    )} */}
                  </div>
                  <div className="p-6">
                    <div className="flex items-center space-x-2 text-sm text-gray-400 mb-2">
                      <span>{blog.date}</span>
                      <span>•</span>
                      <span>{blog.readTime}</span>
                    </div>
                    <h3 className="text-xl font-bold mb-2 line-clamp-2">
                      {blog.title}
                    </h3>
                    <p className="text-gray-400 mb-4">
                      {expandedItems[`blog-${index}`]
                        ? blog.description
                        : blog.description.length > 150
                          ? <>
                            {blog.description.substring(0, 150)}
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggleExpand('blog', index);
                              }}
                              className="text-blue-400 hover:text-blue-300 transition-colors inline ml-1"
                            >
                              ... expand
                            </button>
                          </>
                          : blog.description
                      }
                    </p>
                    <a
                      href={blog.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-300 transition-colors inline-flex items-center"
                    >
                      Read Article
                      <svg className="w-4 h-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-8">Personal Projects</h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {projects.map((project, index) => (
                <div key={index} className="bg-gray-900 rounded-lg overflow-hidden shadow-lg">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <h3 className="text-2xl font-bold mb-2">{project.title}</h3>
                    <p className="text-gray-400 mb-4">{project.description}</p>
                    <a href={project.link} className="text-blue-400 hover:text-blue-300 transition-colors" target="_blank" rel="noopener noreferrer">
                      Explore Project →
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-8">Achievements</h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {achievements.map((achievement, index) => (
                <div key={index} className="bg-gray-900 rounded-lg overflow-hidden shadow-lg">
                  <div className="h-64 overflow-hidden">
                    <img
                      src={achievement.image}
                      alt={achievement.title}
                      className="w-full h-full object-cover object-top"
                    />
                  </div>
                  <div className="p-6">
                    <h3 className="text-2xl font-bold mb-2">{achievement.title}</h3>
                    <p className="text-gray-400 mb-4">{achievement.description}</p>
                    {achievement.link && (
                      <a
                        href={achievement.link}
                        className="text-blue-400 hover:text-blue-300 transition-colors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Details →
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-bold mb-8">Skills</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {skillCategories.map((category, index) => (
                <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg">
                  <div className="flex items-center mb-4">
                    {category.icon}
                    <h3 className="text-xl font-semibold ml-3">{category.name}</h3>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {category.skills.map((skill, skillIndex) => (
                      <span
                        key={skillIndex}
                        className="px-3 py-1 bg-blue-500 bg-opacity-20 text-blue-300 rounded-full text-sm"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>

        {showNotification && (
          <div className="fixed top-4 right-4 bg-gray-800 text-blue-400 px-4 py-2 rounded-md flex items-center space-x-2 shadow-lg border border-blue-500 animate-fade-in-out">
            <Check size={16} className="text-blue-400" />
            <span>Email copied to clipboard!</span>
          </div>
        )}
      </div>

      <style jsx global>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        
        @keyframes fade-in-out {
          0% { opacity: 0; transform: translateY(-1rem); }
          10% { opacity: 1; transform: translateY(0); }
          90% { opacity: 1; transform: translateY(0); }
          100% { opacity: 0; transform: translateY(-1rem); }
        }
        
        .animate-fade-in-out {
          animation: fade-in-out 3s ease-in-out forwards;
        }
      `}</style>
    </div>
  );
};

export default Portfolio;